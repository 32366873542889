import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import { UPDATE_HAS_CREDENTIALS_SUCCESS, FETCH_PS_OWNER_SUCCESS } from '../actions';

import displayReducer from '../reducers/display';
import {
    bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
    notificationCountReducer,
} from '../reducers/header';
import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

const dropdownReducer = (state = initialState.dropdowns, action) => {
    return state;
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

const tenantReducer = (state, action) => {
    let tenant_id;
    let has_credentials;
    let tenants;
    switch (action.type) {
        case UPDATE_HAS_CREDENTIALS_SUCCESS:
            tenant_id = action.payload.tenant_id;
            has_credentials = action.payload.has_credentials;

            return Object.assign({}, state, { [tenant_id]: Object.assign({}, state[tenant_id], { has_credentials }) });
        case FETCH_PS_OWNER_SUCCESS:
            tenants = action.payload.tenants;

            return tenants;
    }

    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        tenants: tenantReducer(state.tenants, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(
            state.notification_count, action),
    });
    return new_state;
};

export const reducers = {
    display: (state = {}, action) => {
        const { type, payload } = action;
        switch (type) {
            case FETCH_PS_OWNER_SUCCESS:
                return {
                    ...state,
                    promo_api_name: payload.promo_api_name,
                };
        }
        return displayReducer(state, action);
    },
    dropdowns: dropdownReducer,
    entities: entitiesReducer,
    identity: identityReducer
};

const rootReducer = combineReducers(reducers);

export default function configurePSSupplierStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
