import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LabelledSelect from './LabelledSelect';

import { createFetchPSOwner } from '../actions';
import { createEditTenantPSCredentialPopup } from '../actions/popup';

import { getTenantOptions } from '../selectors/dropdowns';

import { formatMoney } from '../utils';

class PSSupplierView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant_id: '',
            loading: false,
            has_credentials: '',
            promo_api_name: (_.find(props.owners) || {}).promo_api_name,
        };
        this.state = {
            ...this.state,
            ...this.getStateFromProps(props),
        };
        _.bindAll(this, ['handleChangeFilter', 'handleFilterTenants', 'handleChangePSOwner']);
    }

    getStateFromProps(props) {
        const tenants = _.values(props.tenants);
        const no_credentials_tenants = tenants.filter(t => !this.hasCredential(t));
        return {
            tenants: props.tenants,
            no_credentials_tenant_count: no_credentials_tenants.length,
            credentials_tenant_count: tenants.length - no_credentials_tenants.length,
            no_credentials_po_count: Object.values(props.tenants).length > 0 ?
                no_credentials_tenants.map(t => t.po_count).reduce((a, b) => parseInt(a) + parseInt(b))
                : null,
            total_po_count: Object.values(props.tenants).length > 0 ?
                tenants.map(t => t.po_count).reduce((a, b) => parseInt(a) + parseInt(b))
                : null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.getStateFromProps(nextProps)
        }, function() {
            this.handleFilterTenants();
        });
    }

    handleChangeFilter(e, field) {
        this.setState({ [field]: e, loading: true }, function() {
            if(field === 'promo_api_name') {
                this.handleChangePSOwner();
            }
            this.handleFilterTenants();
        });
    }

    handleChangePSOwner() {
        this.props.onCreateFetchPSOwner(this.state.promo_api_name);
    }

    handleFilterTenants() {
        const { has_credentials } = this.state;
        let tenants = Object.values(this.props.tenants)
            .filter(te => this.state.tenant_id !== '' ? te.tenant_id === this.state.tenant_id : te.tenant_id === te.tenant_id)
            .filter(te => has_credentials === '' || has_credentials === te.has_credentials)
            .reduce((o, t) => { o[t.tenant_id] = t; return o;}, {})
        ;
        this.setState({ tenants, loading: false });
    }

    hasCredential(tenant) {
        return tenant.has_credentials === 'yes';
    }

    renderLoading() {
        return (
            <div className='row popup-content column'>
                <div className='small-12 columns'>
                    <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
                </div>
            </div>
        );
    }

    renderHeader() {
        const { headerStyle } = style;

        return (
            <tr className="cursor-default">
                <td width="10%" style={headerStyle} className="table-cell-sticky">Distributor</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Credentials</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky"># of POs in last 6 months</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Recent PO # in last 6 months</td>
                <td width="10%" style={headerStyle} className="table-cell-sticky">Associated PO value</td>
            </tr>
        );
    }

    renderRow(t) {
        const has_credential = this.hasCredential(t);
        return (
            <tr key={t.tenant_id} onClick={e => {
                e.preventDefault();
                this.props.onCreateEditTenantPSCredentialPopup(t.tenant_id, this.state.promo_api_name);
            }} style={has_credential ? null : { background: '#ff000024'}}>
                <td><b><a target="_blank" href={`/distributor.php?id=${t.tenant_id}`} onClick={e => e.stopPropagation()}>{t.tenant_name}</a></b></td>
                <td>{has_credential ? 'Yes' : 'No'}</td>
                <td>{t.po_count}</td>
                <td>{t.latest_po_number && t.po_with_job_number == 1 ? `${t.latest_job_number}-` : ''}{t.latest_po_number}</td>
                <td>{t.latest_po_total ? formatMoney(t.latest_po_total) : ''}</td>
            </tr>
        );
    }

    renderNoRow() {
        return (
            <tr>
                <td colSpan="12">
                    {Object.values(this.props.owners).length > 0 ?
                        <p>There are no tenants with these search criteria.</p>
                    :
                        <p>We do not have your promostandards services added to commonsku. For more information about promostandards please see <a target="_blank" href="https://promostandards.org/">www.promostandards.org</a>.  If you have recently added endpoints, please contact <a href="mailto:support@commonsku.com">support@commonsku.com</a>.</p>
                    }
                </td>
            </tr>
        );
    }

    render() {
        const { tenant_options, owners } = this.props;
        const { summary_style } = style;

        const owner_options = Object.values(owners).map(e => ({ key: e.promo_api_name, value: e.display_name }));
        const tenant_dropdowns = [{ key: '', value: 'All' }].concat(tenant_options);
        const credential_options = [
            { key: '', value: 'All' },
            { key: 'yes', value: 'Has Credentials' },
            { key: 'no', value: 'No Credentials' }
        ];

        return (
            <div className="main-content" style={{ paddingRight: '12px'}}>
                <div className="row collapse" style={{ maxWidth: 'initial', marginTop: '10px', fontSize: '14px' }}>
                    {owner_options.length > 1 ?
                        <LabelledSelect withMarginBottom className="small-12 medium-4 columns" placeholder="Endpoint Set" value={this.state.promo_api_name} options={owner_options} onChange={e => this.handleChangeFilter(e, 'promo_api_name')} disabled={this.state.loading} />
                    : null}
                    <LabelledSelect withMarginBottom className="small-12 medium-4 columns" placeholder="Distributors" value={this.state.tenant_id} options={tenant_dropdowns} onChange={e => this.handleChangeFilter(e, 'tenant_id')} disabled={this.state.loading} />
                    <LabelledSelect withMarginBottom className="small-12 medium-4 columns end" placeholder="Credentials" value={this.state.has_credentials} options={credential_options} onChange={e => this.handleChangeFilter(e, 'has_credentials')} disabled={this.state.loading} />
                    <p className="small-6 columns" style={{ fontSize: '1rem' }}><b>{this.state.no_credentials_po_count}</b> / {this.state.total_po_count} of POs are processed <b>without credentials</b> in last 6 months</p>
                    <li style={summary_style} className="small-6 columns">
                        <span><b># of Distributors without credentials: </b>{this.state.no_credentials_tenant_count}&nbsp;&nbsp;</span>
                        <span>&nbsp;&nbsp;<b># of Distributors with credentials: </b>{this.state.credentials_tenant_count}</span>
                    </li>
                </div>
                {this.state.loading ?
                    this.renderLoading()
                    :
                    <table style={{ marginBottom: 0 }}>
                        <thead style={{ fontSize: '14px' }}>
                            {this.renderHeader()}
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                            {Object.values(this.state.tenants).length > 0 ? Object.values(this.state.tenants).map(t =>
                                this.renderRow(t)
                            ) :
                                this.renderNoRow()
                            }
                        </tbody>
                    </table>
                }
                <br/><br/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tenants: state.entities.tenants,
        tenant_options: getTenantOptions(state),
        owners: state.entities.owners
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateEditTenantPSCredentialPopup: (tenant_id, promo_api_name) => {
            dispatch(createEditTenantPSCredentialPopup(tenant_id, promo_api_name));
        },
        onCreateFetchPSOwner: (promo_api_name) => {
            dispatch(createFetchPSOwner(promo_api_name));
        }
    };
};

const style = {
    headerStyle: {
        top: '120px',
        background: '#EDF2F5'
    },
    summary_style: {
        float: 'right',
        margin: '5 10px',
        backgroundColor: '#DFF1FB',
        padding: '5px 20px',
        listStyle: 'none'
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(PSSupplierView);
