import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import PSSupplierView from '../components/PSSupplierView';
import { reducers } from '../store/configurePSSupplierStore';
import withReducers from '../store/withReducers';
import { createNewFeaturePopup } from '../actions/popup';
import { oauth } from '../utils';

class PSSupplierApp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { user_id, onCreateNewFeaturePopup } = this.props;

        oauth('GET', `user/${user_id}`, { should_show_popup: true }).then(({ json }) => {
            if(json.show_popup == 1) {
                onCreateNewFeaturePopup();
            }
        });
    }

    render() {
        const { popups } = this.props;

        return (
            <div>
                <Header>
                    <div>
                        <div className="large-8 columns">
                            <h4 style={{ display: 'inline-block' }}>
                                <span>Promostandards</span>
                            </h4>
                        </div>
                    </div>
                </Header>
                <MainSection popups={popups}>
                    <PSSupplierView />
                </MainSection>
                <Overlay popups={popups} />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    identity: state.identity,
    popups: getPopups(state),
    user_id: state.identity.user_id
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateNewFeaturePopup: () => {
      dispatch(createNewFeaturePopup());
    }
  };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(PSSupplierApp), reducers, true);
